<template>
    <div class="container is-max-widescreen mod-ribbon ribbon-consultants-find">
        <div class="columns">
            <div class="column">
                <h1>1. Hvilke kompetencer søger du?</h1> 
                <label for="competenceCategories" class="is-sr-only">Vælg kompetenceområde</label>
                <select id="competenceCategories" @change="competenceCategoriesChange($event)" class="select mr-2">
                    <option value="">- Vælg kompetenceområde -</option>
                    <option v-for="(value, propertyName) in competenceCategories" :key="propertyName" :value="propertyName">{{ value.name }} ({{ Object.keys(value.competences).length }})</option>
                </select>

                <label for="competences" class="is-sr-only">Vælg kompetence</label>
                <select id="competences" @change="competencesChange($event)" class="select" :disabled="competences.length == 0">
                    <option value="">- Vælg kompetence -</option>
                    <option v-for="(value, propertyName) in competences" :key="propertyName" :value="propertyName">{{ value.name }}</option>
                </select>

                <ul class="competences-selected">
                    <li v-for="competence in global.competencesSelected" :key="competence" class="tag is-info mr-1 mt-1" @click="competenceRemove(competence)" :title="['Fravælg kompetencen \'']+[competenceFromAlias(competence)]+['\'']">
                        {{ competenceFromAlias(competence) }}
                        <button class="delete is-small"></button>
                    </li>
                </ul>
                <div v-if="global.consultantsFound.length || distanceOption.checked" class="ribbon-consultants-find--distance field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <label class="checkbox">
                                <input type="checkbox" v-model="distanceOption.checked"
                                    @change="distanceOptionChange()"
                                > Begræns søgning til maks. 
                            </label>
                        </div>
                        <div class="field">
                            <label for="distance" class="is-sr-only">Angiv afstand (km)</label>
                            <input id="distance" class="input is-small" type="number" min="20" step="10" placeholder="km" :disabled="!distanceOption.checked"
                                v-model="distanceOption.distance"
                                @input="distanceOptionChange()"
                            >
                        </div>
                        <div class="field">
                            fra
                        </div>
                        <div class="field">
                            <label for="zip" class="is-sr-only">Angiv postnr.</label>
                            <input id="zip" class="input is-small" type="number" min="1" step="1" placeholder="postnr." :disabled="!distanceOption.checked"
                                v-model="distanceOption.zip"
                                @input="distanceOptionChange()"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="global.competencesSelected.length">
                    <h2>Disse Medvirkere matcher din søgning</h2>
                    <div v-if="!global.consultantsFound.length" class="table-container">
                        <p>Ingen konsulenter matcher dine valgte kompetence(r).</p>
                    </div>
                    <div v-if="global.consultantsFound.length" class="table-container">
                        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th class="favorite">Vælg</th>
                                    <th>Navn</th>
                                    <th>Postnr.</th>
                                    <th>Kompetencer</th>
                                    <!-- <th class="distance">Km</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="consultant in global.consultantsFound" :key="consultant.consultantId">
                                    <td class="favorite">
                                        <span role="button" class="icon favorite" @click="consultantFavorite(consultant)">
                                            <i :class="this.global.favoritesConsultants.hasOwnProperty(consultant.consultantId) ? 'fas fa-check-square has-text-primary-dark' : 'far fa-square'" :ref="'starredConsultant' + consultant.consultantId"></i>
                                        </span>
                                    </td>
                                    <td class="name">
                                        <a :href="['/medvirker/']+[consultant.consultantSlug]+['-']+[consultant.consultantId]" :title="consultant.consultantDescription" target="_blank">
                                            {{ consultant.consultantName }}

                                            
                                        </a>
                                    </td>
                                    <td>{{ consultant.consultantZip }}</td>
                                    <td>
                                        <div class="progress-wrapper">
                                            <progress :class="['progress ' ]+[consultant.count / global.competencesSelected.length > 0.5 ? 'is-success' : 'is-warning']" :value="consultant.count / global.competencesSelected.length * 100" max="100" :title="['Har ']+[consultant.count]+[' af ']+[global.competencesSelected.length]+[' søgte kompetencer']">{{ consultant.count }}/{{ global.competencesSelected.length }}</progress>                                        
                                            <p class="progress-value" :title="['Har ']+[consultant.count]+[' af ']+[global.competencesSelected.length]+[' søgte kompetencer']">{{ consultant.count }}/{{ global.competencesSelected.length }}</p>
                                        </div>
                                    </td>
                                    <!-- <td class="distance">-</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shared from '@/shared'
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    data() {
        return {
            competenceCategories: [],
            competences: [],
            competenceCategorySelected: null,
            distanceOption: {
                checked: false,
                distance: 50,
                zip: null
            },
            consultantsFound: []
        }
    },
    created() {
        this.competencesLoad();
    },
    methods: {
        competencesLoad() {
            axios.get(process.env.VUE_APP_API_ROOT + '/competences')
                .then(response => {
                    this.competenceCategories = response.data;
                })   
        },
        competenceCategoriesChange(event) {
            var selectedCategory = this.competenceCategories[event.target.value];
            if(selectedCategory != undefined) {
                this.competenceCategorySelected = selectedCategory.id;
                this.competences = this.competenceCategories[event.target.value].competences;
            } else {
                this.competenceCategorySelected = null;
                this.competences = [];
            }
        },
        competencesChange(event) {
            if(event.target.value != '') {
                var competenceSelected = this.competenceCategorySelected + '-' + parseInt(event.target.value); 
                if(this.global.competencesSelected.includes(competenceSelected)) {
                    const index = this.global.competencesSelected.indexOf(competenceSelected);
                    if (index > -1) {
                        this.global.competencesSelected.splice(index, 1);
                    }
                } else {
                    this.global.competencesSelected.push(competenceSelected);
                }
                this.consultantsSearch();
            }
        },
        competenceFromAlias(alias, asObject = false) {
            var arrIds = alias.split('-');
            var competence = this.competenceCategories[arrIds[0]].competences[arrIds[1]];
            var object =  {
                    category: {
                        id: arrIds[0],
                        name: this.competenceCategories[arrIds[0]].name
                    },
                    competence: {
                        id: arrIds[1],
                        name: competence != undefined ? competence.name : ''
                    }
                };

            if(asObject) {
                return object;
            } else {
                return object.category.name + ' » ' + object.competence.name;
            }
        },
        competenceRemove(competence) {
            const index = this.global.competencesSelected.indexOf(competence);
            if (index > -1) {
                this.global.competencesSelected.splice(index, 1);
                this.consultantsSearch();
            }
        },
        consultantsSearch() {
            var competencesSelected = this.global.competencesSelected;
            var competencesIdsArr = [];
            for (var i = 0; i < competencesSelected.length; i++) {
                competencesIdsArr.push(parseInt(competencesSelected[i].split('-')[1]));
            }
            axios.get(process.env.VUE_APP_API_ROOT + '/consultantsSearch?competences=' + competencesIdsArr.join() + (this.distanceOption.checked && this.distanceOption.distance && this.distanceOption.zip ? '&zip=' + this.distanceOption.zip + '&distance=' + this.distanceOption.distance : ''))
            .then(response => {
                this.global.consultantsFound = response.data;
            })  
        },
        consultantFavorite(consultant) {
            if(this.global.favoritesConsultants.hasOwnProperty(consultant.consultantId)) {
                // Remove, if consultant is already in favorites
                delete this.global.favoritesConsultants[consultant.consultantId];
                this.$refs['starredConsultant' + consultant.consultantId].classList.value = 'far fa-square';
            } else {
                // Add, if  consultant is NOT already in favorites
                var id = consultant.consultantId;
                delete consultant.consultantDescription;
                consultant.isRecipient = true;
                this.global.favoritesConsultants[id] =  consultant;
                this.$refs['starredConsultant' + consultant.consultantId].classList.value = 'fas fa-check-square has-text-primary-dark';
            }
            //setCookie('favoritesConsultants', JSON.stringify(this.global.favoritesConsultants), 30)
        },
        distanceOptionChange() {
            let distance = parseInt(this.distanceOption.distance);
            let zip = parseInt(this.distanceOption.zip);
            if(
                (this.distanceOption.checked && distance > 0 && zip >= 1000) || 
                !this.distanceOption.checked
            ) {
                this.consultantsSearch();
            }            
        }
    }
})
</script>