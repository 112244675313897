<template>
    <div class="container is-max-widescreen mod-ribbon ribbon-usps">
        <div class="columns">
            <div class="column">
                <h2>1200+ konsulenter</h2>
                <p>Find de kompetencer og ressourcer, der passer til opgaven</p>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <h3>... betaler for at være med</h3>
                <p>- så de mener det alvorligt</p>
            </div>
            <div class="column">
                <h3>... kan vælge max 5 kompetencer</h3>
                <p>- så søgningen leverer reel kvalitet</p>
            </div>
            <div class="column">
                <h3>... ved ikke, hvem der søger</h3>
                <p>- så de byder ind med stort fokus</p>
            </div>
        </div>
    </div>
</template>