<template>
  <Header/>
  <h1>{{ currentRoute.params.url }}</h1>
  <UspsHero/>
  <ConsultantsFind/>
  <TaskCreate/>
  <Usps/>
  <Footer/>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Usps from '@/components/Usps.vue'
import UspsHero from '@/components/UspsHero.vue'
import ConsultantsFind from '@/components/ConsultantsFind.vue'
import TaskCreate from '@/components/TaskCreate.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    
    return {
      global
    }
  },
  components: {
    Header,
    Usps,
    UspsHero,
    ConsultantsFind,
    TaskCreate,
    Footer
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>