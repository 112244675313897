import ax from 'axios'
export const axios = ax

export default {
    /*
    loadTask(global, taskId = 0) {
        if(global.user.tasksInDraft.length > 0) {
            if(taskId == 0) {
                taskId = global.user.tasksInDraft[0].id;
            } 
            axios.get(process.env.VUE_APP_API_ROOT + '/tasks?mode=get&id=' + taskId + '&userAlias=' + getCookie('userAlias'))
            .then(response => {
                console.log(response.data);
                //return response.data;
                //return 'd';
            })
        }
    }
    */
}