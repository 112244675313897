<template>
    <div class="container is-max-widescreen mod-ribbon">
        <div class="columns">
            <div class="column is-three-fifths">
                <p>Få ressourcer og kompetencer</p>
                <h1>Find din konsulent</h1>
            </div>
            <div class="column content">
                <ul>
                    <li>Søg anonymt</li>
                    <li>Få spidskompetencer</li>
                    <li>Undgå faste omkostninger</li>
                </ul>
            </div>
        </div>
    </div>
</template>