<template>
    <div v-if="global.competencesSelected.length && !global.task.showSubmitReceipt" class="container is-max-widescreen mod-ribbon ribbon-consultants-invite">
        <div class="columns">
            <div class="column">
                <h2>2. Beskriv opgaven og lad medvirke.dk håndtere dit udbud</h2>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="step step-description">
                    <div>
                        <div class="field">
                            <label for="global.task.subject" class="is-sr-only">Emne (opgavetitel), max 100 tegn</label>
                            <div class="control">
                                <input id="global.task.subject" class="input" type="text" maxlength="100" placeholder="Emne (opgavetitel), max 100 tegn" required
                                    v-model="global.task.subject"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>

                        <div class="field">
                            <label for="global.task.description" class="is-sr-only">Beskrivelse, max {{ descriptionMaxLength }} tegn. Vedhæft evt. PDF-er for resten.</label>
                            <div class="control">
                                <textarea id="global.task.description" class="textarea" :maxlength="descriptionMaxLength" :placeholder="['Beskrivelse, max '] + descriptionMaxLength + [' tegn']" required
                                    v-model="global.task.description"
                                    @change="onTaskChange()"
                                    @keyup="onDescriptionChange()"
                                ></textarea>
                            </div>
                            <p class="has-text-right" :class="descriptionMaxLength - descriptionChars < 15 ? 'has-text-danger' : ''">{{ descriptionMaxLength - descriptionChars }} tegn tilbage</p>
                        </div>

                        <div class="field">
                            <div class="control">
                                <label for="global.task.deadline">Frist for indsendelse af tilbud (min. {{global.task.deadlineDaysMin}} dage)</label>
                                <input id="global.task.deadline" class="input" type="date" :min="global.task.deadlineMin" 
                                    v-model="global.task.deadline"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>

                        <div class="module-upload">
                            <div class="file">
                                <label class="file-label">
                                    <input class="file-input" id="id" type="file" ref="file" @change="selectFile()" >
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-file-pdf"></i>
                                        </span>
                                        <span class="file-label">
                                            Upload evt. PDF-ere (max 100 MB)
                                        </span>
                                    </span>
                                </label>
                            </div>
                            <p v-if="uploadResponse.hasOwnProperty('success') && !uploadResponse.success">Filen kunne ikke uploades. Prøv venligst igen. <br>Fejlbesked: {{ uploadResponse.message != '' ? uploadResponse.message : uploadResponse.reason }}</p>
                            <div v-if="global.task.uploads.length">
                                <strong>Vedhæftede filer</strong>
                                <ul>
                                    <li v-for="file in global.task.uploads" :key="file.id">
                                        <span class="icon pointer" role="button" @click="deleteFile(file.fileOriginal, file.file, file.token)" :title="['Slet filen ´']+[file.fileOriginal]+['´']">
                                            <i class="fas fa-trash"></i>
                                        </span>
                                        <a :href="[apiRoot] + ['/download?file='] + [file.file] + ['&token='] + [file.token]">{{ file.fileOriginal }}</a>
                                    </li>
                                </ul>
                            </div>
                            <progress class="progress" :value="progress" max="100" v-if="!hideProgress"></progress>
                        </div>
                    </div>
                </div>

                <div class="step step-contactinfo">
                    <h3>3. Om dig</h3>
                    <div>
                        <div class="field">
                            <div class="control">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="global.task.company.anonymous"
                                        @change="onTaskChange()"
                                    >
                                    Lad min virksomhed optræde anonymt
                                    <p v-if="global.task.company.anonymous" class="is-size-7">Vi viser kun postnr. - men medvirke.dk skal stadigvæk kende din virksomheds kontaktdata mv.</p>
                                </label>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="global.task.company.vat">CVR-nummer *</label>
                            <div class="control">
                                <input id="global.task.company.vat" class="input" type="number" maxlength="8" placeholder="Tast CVR-nummer, og vi henter info for dig" required
                                    v-model="global.task.company.vat"
                                    @keyup="handleVatChange($event)"
                                    @paste="handleVatChange($event)"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>

                        <div class="field">
                            <label class="label" for="global.task.company.name">Virksomhedens navn *</label>
                            <div class="control">
                                <input id="global.task.company.name" class="input" type="text" required
                                    v-model="global.task.company.name"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="global.task.company.address">Adresse *</label>
                            <div class="control">
                                <input id="global.task.company.address" class="input" type="text" required
                                    v-model="global.task.company.address"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label" for="global.task.company.zip">Postnr. *</label>
                                    <input id="global.task.company.zip" class="input" type="number" minlength="4" maxlength="4" required
                                        v-model="global.task.company.zip"
                                        @change="onTaskChange()"
                                    >
                                </div>
                                <div class="field">
                                    <label class="label" for="global.task.company.city">By *</label>
                                    <input id="global.task.company.city" class="input" type="text" required
                                        v-model="global.task.company.city"
                                        @change="onTaskChange()"
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label" for="global.task.contact.name">Kontaktperson *</label>
                            <div class="control">
                                <input id="global.task.contact.name" class="input" type="text" placeholder="Kontaktpersons navn" required 
                                    v-model="global.task.contact.name"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="global.task.contact.email">Kontakt-e-mail *</label>
                            <div class="control">
                                <input id="global.task.contact.email" class="input" type="text"  placeholder="E-mail, vi skal sende tilbuddene til" required
                                    v-model="global.task.contact.email"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="global.task.contact.phone">Kontakt-telefon</label>
                            <div class="control">
                                <input id="global.task.contact.phone" class="input" type="text" placeholder="Kontaktpersons telefon"
                                    v-model="global.task.contact.phone"
                                    @change="onTaskChange()"
                                >
                            </div>
                        </div>
                        <button class="button is-primary" :disabled="!global.task.isSubmitable" :title="global.task.isSubmitable ? '' : 'Du mangler at udfylde nogle felter, før du kan sende opgaven'" @click="submitTask()">Send opgaven i udbud</button>
                    </div>

                </div>
            </div>
            <div class="column column-mail">
                <div class="from">
                    <p class="label">Fra</p>
                    <p v-if="global.task.company.anonymous">Anonym virksomhed <span v-if="global.task.company.zip != undefined && global.task.company.zip.length == 4">(postnr. {{global.task.company.zip}})</span></p>
                    <p v-if="!global.task.company.anonymous">
                        Virsomheden {{ global.task.company.name != '' && global.task.company.name != undefined ? global.task.company.name : '<Virksomhedsens navn>' }}
                        <span v-if="global.task.company.vat != undefined"> (CVR: {{ global.task.company.vat }})</span>
                    </p>
                </div>
                <div class="to">
                    <p class="label">Til</p>
                    <ul>
                        <li v-for="(value, consultantId) in global.favoritesConsultants" :key="consultantId" class="tag is-info mr-1 mt-1">
                            <label class="checkbox">
                                <span class="icon" @click="removeFavorite(consultantId)" title="Fjern denne modtager fra mailen">
                                    <i class="fas fa-times-circle"></i>
                                </span>
                                {{ value.consultantName }}
                            </label>
                        </li>
                    </ul>
                </div>

                <div class="subject">
                    <p class="label">Emne</p>
                    <p>{{ global.task.subject }}</p>
                </div>
                <div class="content" v-html="global.task.description ? global.task.description.replaceAll('\n', '<br>') : ''"></div>
                <div v-if="global.task.uploads.length">
                    <strong>Vedhæftede filer</strong>
                    <ul>
                        <li v-for="file in global.task.uploads" :key="file.id">
                            <a :href="[apiRoot] + ['/download?file='] + [file.file] + ['&token='] + [file.token]">{{ file.fileOriginal }}</a>
                        </li>
                    </ul>
                </div>

                <button class="button is-primary" :disabled="!global.task.isSubmitable" :title="global.task.isSubmitable ? '' : 'Du mangler at udfylde nogle felter, før du kan sende opgaven'" @click="submitTask()">Send opgaven i udbud</button>
            </div>
        </div>
    </div>
    <TaskCreated/>
</template>

<script>
//import shared from '@/shared'
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import UploadService from "../services/UploadFilesService";
import ax from 'axios'
export const axios = ax
import TaskCreated from '@/components/TaskCreated.vue'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    components: {
        TaskCreated
    },
    data() {
        return {
            descriptionMaxLength: this.global.descriptionMaxLength,
            descriptionChars: 0,
            apiRoot: process.env.VUE_APP_API_ROOT,

            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            hideProgress: true,
            uploadResponse: {},
        }
    },
    mounted() {
        this.loadFromLocalstorage();

    },
    methods: {
        loadFromLocalstorage() {
            if (localStorage.getItem("global.task") != null) {
                this.global.task = JSON.parse(localStorage.getItem('global.task'));
                this.isTaskSubmitable();
                this.onDescriptionChange();
                // TODO: Give user chance to remove loaded data
            }
        },
        onTaskChange() {
            localStorage.setItem('global.task', JSON.stringify(this.global.task));
            this.isTaskSubmitable();
        },
        isTaskSubmitable() {
            if(
                this.global.task.subject != '' && this.global.task.subject != undefined &&
                this.global.task.description != '' && this.global.task.description != undefined &&
                this.global.task.company.vat != undefined && this.global.task.company.vat.toString().length == 8 && 
                this.global.task.company.name != '' && this.global.task.company.name != undefined &&
                this.global.task.company.address != '' && this.global.task.company.address != undefined &&
                this.global.task.company.zip != '' && this.global.task.company.zip != undefined &&
                this.global.task.company.city != '' && this.global.task.company.city != undefined &&
                this.global.task.contact.name != '' && this.global.task.contact.name != undefined &&
                this.global.task.contact.email != '' && this.global.task.contact.email != undefined &&
                Object.keys(this.global.favoritesConsultants).length > 0
            ) {
                this.global.task.isSubmitable = true;
            } else {
                this.global.task.isSubmitable = false;
            }
        },
        handleVatChange(e) {
            var vat = parseInt(e.target.value);
            console.log(vat);
            if(vat.toString().length == 8) {
                axios.get('https://cvrapi.dk/api?search=' + vat + '&country=dk')
                .then(response => {
                    if(!response.data.hasOwnProperty('error')) {
                        // Always update company data (if user changes VAT, it makes sense to replace with new data from VAT API)
                        this.global.task.company.name = response.data.name;
                        this.global.task.company.address = response.data.address;
                        this.global.task.company.zip = response.data.zipcode;
                        this.global.task.company.city = response.data.city;

                        // Only update if not empty (contact data is sometimes not related to that comming from VAT API)
                        var contactName = this.global.task.contact.name;
                        if(response.data.owners != undefined && (contactName == '' || contactName == undefined)) {
                            this.global.task.contact.name = response.data.owners[0].name;
                        }
                        var contactEmail = this.global.task.contact.email;
                        if(contactEmail == '' || contactEmail == undefined) {
                            this.global.task.contact.email = response.data.email;
                        }
                        var contactPhone = this.global.task.contact.phone;
                        if(contactPhone == '' || contactPhone == undefined) {
                            this.global.task.contact.phone = response.data.phone;
                        }

                        // Data has been loaded to fields from externally, so handle as if user has changed them 
                        this.onTaskChange();
                    }
                })  
                this.isTaskSubmitable();
            }
        },
        removeFavorite(consultantId) {
            if(this.global.favoritesConsultants.hasOwnProperty(consultantId)) {
                delete this.global.favoritesConsultants[consultantId];
                //this.$refs['starredConsultant' + consultantId].classList.value = 'far fa-square';
                //setCookie('favoritesConsultants', JSON.stringify(this.global.favoritesConsultants), 30);
            }
            this.isTaskSubmitable();
        },
        /*
        addRecipient(consultantId, add = true) {
            const index = this.global.task.mailRecipients.indexOf(consultantId);
            if(add) {
                // Add consultantId if not already present
                if (index == -1) {
                    this.global.task.mailRecipients.push(consultantId);
                }
            } else {
                // Remove consultantId
                const index = this.global.task.mailRecipients.indexOf(consultantId);
                if (index > -1) {
                    this.global.task.mailRecipients.splice(index, 1);
                }
            }
            this.onTaskChange();
            this.isTaskSubmitable();
        },
        */
        submitTask() {
            if(confirm('Vil du sende opgaven ud til de valgte medvirkere? Du kan ikke rette i opgaven, når den er sendt.')) {
                var url = process.env.VUE_APP_API_ROOT + '/taskSubmit';
                var data = {
                    recipients: this.global.favoritesConsultants,
                    task: this.global.task
                };

                axios.post(url, data)
                .then(response => {
                    if(response.data.hasOwnProperty('success') && response.data.success) {
                        // Save the task hash to client
                        this.global.task.submittedTasks.push(response.data.hash);

                        // Reset task data, so user can submit new
                        this.global.task.subject = '';
                        this.global.task.description = '';

                        const submittedTasks = this.global.task.submittedTasks;
                        this.global.task.submittedTasks = submittedTasks;
                        this.global.task.showSubmitReceipt = true;
                        this.onTaskChange();
                    } else {
                        
                    }
                })
            }
        },
        
        onDescriptionChange() {
            this.descriptionChars = this.global.task.description != undefined ? this.global.task.description.length : 0;
        },
        selectFile() {
            //this.sendTaskData('newUpload', 'uploadOnWay');
            this.selectedFiles = this.$refs.file.files;
            this.upload(this.global.task);
        },
        upload(toObject) {
            this.progress = 0;
            this.hideProgress = false;

            this.currentFile = this.selectedFiles.item(0);
            UploadService.upload(this.currentFile, event => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then(response => {
                    this.uploadResponse = response.data;
                    if(response.data.success) {
                        this.global.task.uploads.push( {
                            id: response.data.id,
                            file: response.data.file,
                            fileOriginal: response.data.fileOriginal,
                            token: response.data.token,
                        });
                        this.onTaskChange();
                    }
                    this.hideProgress = true;
                })
                .catch(() => {
                    this.progress = 0;
                    this.uploadResponse = {
                        success: false,
                        reason: 'unknown'
                    }
                    //this.message = "Could not upload the file!";
                    this.currentFile = undefined;
                });

            this.selectedFiles = undefined;
        },
        deleteFile(fileOriginal, file, token) {
            if(confirm('Vil du slette filen "' + fileOriginal + '"?')) {
                axios.get(process.env.VUE_APP_API_ROOT + '/upload?mode=delete&file=' + file + '&token=' + token)
                    .then(response => {
                        console.log(response.data);
                        // Update uploads lists
                        var updatedUploads = [];
                        for (var i = 0; i < this.global.task.uploads.length; i++) {
                            if(this.global.task.uploads[i].file != file) {
                                updatedUploads.push(this.global.task.uploads[i]);
                            }
                        }
                        this.global.task.uploads = updatedUploads;
                        this.onTaskChange();
                    }) 
            }
        }
    }
})
</script>