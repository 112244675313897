<template>
    <div v-if="global.task.showSubmitReceipt" class="container is-max-widescreen mod-ribbon ribbon-consultants-task-submitted">
        <div class="columns">
            <div class="column">
                <article class="message is-primary">
                    <div class="message-header">
                        <h2>Opgaven er nu sendt i udbud!</h2>
                        <button class="delete" aria-label="delete" @click="hideSubmitReceipt"></button>
                    </div>
                    <div class="message-body">
                        <p>Din opgave er nu sendt til de valgte Medvirkere. Medvirkerne har fået besked på at sende tilbud inden den <strong>{{ formatISOdateToFriendly(global.task.deadline) }}</strong>. Du får tilbuddene via medvirke.dk på din e-mail <strong>{{ global.task.contact.email }}</strong>.</p>
                        <p>Vi har sendt en kvittering og mere information til din e-mail <strong>{{ global.task.contact.email }}</strong>.</p>
                        <p><a href="/" @click="hideSubmitReceipt">Gå til forsiden</a></p>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
//import shared from '@/shared'
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import ax from 'axios'
export const axios = ax

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    data() {
        return {

        }
    },
    methods: {
        hideSubmitReceipt() {
            this.global.task.showSubmitReceipt = false;
            this.global.favoritesConsultants = {};
            //this.global.task.mailRecipients = []; 
            localStorage.removeItem('global.task');
            this.$router.push({ path: '/' });
        },
        formatISOdateToFriendly(ISOdate) {
            // Takes yyyy-mm-dd and formats it like dd.mm.yyyy
            let day = ISOdate.substr(8,2);
            let month = ISOdate.substr(5,2);
            let year = ISOdate.substr(0,4);
            return day + '.' + month + '.' + year;
        }
    }
})
</script>